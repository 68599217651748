import gql from "graphql-tag";

export const CREATE_USER_MUTATION = gql`
    mutation createUser($input: CreateUserInput){
        createUser(input: $input){
            id
        }
    }
`

export const UPDATE_USER_MUTATION = gql`
    mutation updateUser($id: ID!, $input: UpdateUserInput){
        updateUser(id: $id, input: $input) {
            id
        }
    }
`

export const DELETE_USER_MUTATION = gql`
    mutation deleteUser($id: ID!) {
        deleteUser(id: $id) {
            id
        }
    }
`
