<template>
  <Page pageTitle="Profile" isFluid customClass="is-profile mt-6" :hasRightBar="false">
    <!-- Response message -->
    <Columns v-if="errors && errors.length > 0">
      <Column>
        <article v-for="(error, index) in errors" :key="index" class="message is-danger mb-2">
          <div class="message-body py-4">
            {{ error[0] }}
          </div>
        </article>
      </Column>
      <Column/>
    </Columns>
    <Columns v-if="success">
      <Column>
        <article class="message is-success mb-2">
          <div class="message-body py-4">
            {{ success }}
          </div>
        </article>
      </Column>
      <Column/>
    </Columns>
    <Columns>
      <Column customClass="is-flex is-justify-content-flex-start is-flex-direction-column">
        <form @submit.prevent="profileSubmit">
          <Columns>
            <Column>
              <h4 class="title is-4">Account</h4>
            </Column>
          </Columns>
          <!-- Account Fields -->
          <Columns>
            <Column>
              <div class="field">
                <label class="label">Name</label>
                <div class="control">
                  <input class="input" type="text" v-model="user.name" placeholder="Name">
                </div>
              </div>
            </Column>
            <Column>
              <div class="field">
                <label class="label">Email</label>
                <div class="control has-icons-right">
                  <input class="input" type="email" v-model="user.email" placeholder="Email">
                  <span class="icon is-small is-right">
                    <unicon name="envelope" fill="black"/>
                  </span>
                </div>
              </div>
            </Column>
          </Columns>
          <Columns>
            <Column>
              <div class="field">
                <label class="label">DOB</label>
                <div class="control">
                  <input class="input" type="date" v-model="user.dob" placeholder="Date of Birth">
                </div>
              </div>
            </Column>
            <Column>
              <div class="field">
                <label class="label">Username</label>
                <div class="control has-icons-right">
                  <input class="input" type="text" v-model="user.username" placeholder="Username">
                </div>
              </div>
            </Column>
          </Columns>
          <Columns>
            <Column>
              <div class="field">
                <label class="label">Password</label>
                <div class="control has-icons-right">
                  <input class="input" v-model="user.password" type="password" placeholder="Password">
                  <!--                  <password-meter :input="user.password" />-->
                </div>
              </div>
            </Column>
          </Columns>
          <Columns>
            <Column>
              <div class="field">
                <label class="label">Role</label>
                <div class="control">
                  <div class="select">
                    <select v-model="user.role">
                      <option v-for="(role, index) in roles" :value="role.name" :key="index">{{ role.name }}</option>
                    </select>
                  </div>
                </div>
              </div>
            </Column>
            <Column>
              <div class="field">
                <label class="label is-invisible">Status</label>
                <div class="control">
                  <label class="checkbox">
                    <input type="checkbox" v-model="user.enabled">
                    Enabled
                  </label>
                </div>
              </div>
            </Column>
          </Columns>
          <!-- Gitlab Fields -->
          <Columns customClass="mt-3">
            <Column>
              <h4 class="title is-4">GitLab</h4>
            </Column>
          </Columns>
          <Columns>
            <Column>
              <div class="field">
                <label class="label">GitLab ID</label>
                <div class="control">
                  <input class="input" type="text" v-model="user.gitlab_id" placeholder="GitLab ID">
                </div>
              </div>
            </Column>
            <Column>
              <div class="field">
                <label class="label">GitLab Token</label>
                <div class="control has-icons-right">
                  <input class="input" type="text" v-model="user.gitlab_token" placeholder="GitLab Token">
                </div>
              </div>
            </Column>
          </Columns>

          <div class="field is-grouped mt-5">
            <div class="control">
              <button type="submit" class="button is-success">Save</button>
            </div>
          </div>
        </form>
      </Column>
      <Column customClass="is-flex is-justify-content-center is-align-items-center is-flex-direction-column">
        <Columns>
          <Column>
            <Columns>
              <Column>
                <figure v-if="user.image_name" class="image is-256x256">
                  <img :src="`${cmsUrl}images/profile/${user.image_name}`" alt="Profile Image">
                </figure>
              </Column>
            </Columns>
            <Columns>
              <Column>
                <div class="field">
                  <div class="file" :class="{'has-name': showPreview}">
                    <label class="file-label">
                      <input class="file-input" type="file" name="image-uploader"
                             accept="image/png, image/gif, image/jpeg" @change="onFileChange">
                      <span class="file-cta">
                        <span class="icon is-small is-right">
                          <unicon name="image-plus" fill="black"/>
                        </span>
                        <span class="file-label ml-2">
                          Choose...
                        </span>
                      </span>
                      <span v-if="showPreview" class="file-name">
                        {{imageName}}
                      </span>
                    </label>
                  </div>
                </div>
              </Column>
            </Columns>
            <Columns v-if="showPreview">
              <Column>
                <figure class="image is-64x64">
                  <img :src="`${imagePreview}`">
                </figure>
              </Column>
            </Columns>
          </Column>
        </Columns>
      </Column>
    </Columns>
  </Page>
</template>

<script>
import {defineAsyncComponent, reactive, toRefs} from "vue";
import {useUser} from "@/use/useUser";
import {useMutation, useQuery, useResult} from "@vue/apollo-composable";
import {CREATE_USER_MUTATION, UPDATE_USER_MUTATION} from "@/graphql/mutations/userMutation";
import {GET_ALL_ROLES} from "@/graphql/queries/userQuery";

export default {
  components: {
    // PasswordMeter: defineAsyncComponent(() => import("@/components/ds/PasswordMeter")),
    Page: defineAsyncComponent(() => import("@/components/layout/Page.vue")),
    Columns: defineAsyncComponent(() => import("@/components/ds/Columns.vue")),
    Column: defineAsyncComponent(() => import("@/components/ds/Column.vue"))
  },
  setup() {
    const cmsUrl = process.env.VUE_APP_CMS_URL
    const {currentUser} = useUser()
    const {id, name, email, username, dob, gitlab_id, gitlab_token, roles, enabled, image_name} = currentUser.value

    const state = reactive({
      roles: [],
      user: {
        name: name,
        email: email,
        username: username,
        dob: dob,
        gitlab_id: gitlab_id,
        gitlab_token: gitlab_token,
        password: "",
        enabled: enabled,
        role: roles[0],
        image_name: image_name ?? null,
        image_upload: null,
      },
      showPreview: false,
      imagePreview: null,
      imageName: "",
      errors: [],
      success: ""
    })

    const {result} = useQuery(GET_ALL_ROLES, null, {
      clientId: "cmsClient",
    });

    state.roles = useResult(result, [], data => data.roles);

    const {mutate: updateUser, onError, onDone, error} = useMutation(UPDATE_USER_MUTATION, {
      clientId: "cmsClient",
      context: {
        hasUpload: true
      }
    });

    const onFileChange = (event) => {
      let file = event.target.files[0]
      state.user.image_upload = file
      state.imageName = file.name
      state.imagePreview = URL.createObjectURL(file)
      state.showPreview = true
    }

    const profileSubmit = () => {
      const input = {...state.user}

      if (input.password.length <= 0) {
        delete input.password
      }

      if (!input.image_upload) {
        delete input.image_upload
      }

      updateUser({
        id: id,
        input: input,
      })

      onDone((data) => {
        state.success = "Success!"
        state.errors = []
      })

      onError(({graphQLErrors}) => {
        state.errors = Object.values(graphQLErrors[0].extensions.validation)
        state.success = ""
      });
    }

    return {
      ...toRefs(state),
      profileSubmit,
      onFileChange,
      cmsUrl
    }
  }
}
</script>

<style lang="scss" scoped>

.file-name {
  max-width: 8em;
}
</style>
